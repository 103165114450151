import React, { Component } from 'react';
import ReactGA from 'react-ga';
import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Home, LandingPage, School, ProgrammesPage } from './views';

class App extends Component {
  state = {};

  programmes = [
    {id: 1, title: 'Aide-Soignant(e) Généraliste'},
    {id: 2, title: 'Infirmier(e)'},
    {id: 3, title: 'Infirmier(e) Spécialisé(e): Gérontologie'},
    {id: 4, title: 'Sage-Femme/Maïeuticien'},
    {id: 5, title: 'Technicien(ne) Medico-Sanitaire: Kinésithérapie'},
    {id: 6, title: 'Technicien(ne) Medico-Sanitaire: Psychomotricité & Relaxation'}
  ];

  componentDidMount() {
    ReactGA.initialize('UA-246827335-1');
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
      <div>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/temp" element={<HomeTemp />}/>
          <Route path="/home" element={<Home />}/>
          <Route path="/school" element={<School />}/>
          <Route path="/programmes" element={<ProgrammesPage programmes={this.programmes} />}/>
          <Route path="/landing" element={<LandingPage />}/>
        </Routes>
      </div>
    );
  }
}

class HomeTemp extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.tsx</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div>
    );
  }
}

export default App;
