import React, { Component } from 'react';
import { Col } from 'reactstrap';
import flag from '../../assets/cameroon.png';
import '../../styles/style.css';

/**
 * TODO
 * - Language redirection
 */
class Language extends Component<{[name: string]: any}> {

    render() {
        return (
            <React.Fragment>
                <div id='language' className='container'>
                    <div className='row'>
                        <Col xs={5} md={6} className='language-img'><img src={flag} className='language-img-flag' alt='cameroon flag'/></Col>
                        <Col xs={7} md={6} className='language-list'>
                            <span className={this.props.language === 'FR' ? 'language-item-selected' : 'language-item'}
                                onClick={this.props.toggleLanguage}>FR</span>
                            <span className={this.props.language === 'EN' ? 'language-item-selected' : 'language-item'}
                                onClick={this.props.toggleLanguage}>EN</span>
                        </Col>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export { Language };