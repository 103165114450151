import React, { Component } from 'react';
import { Mission } from './Mission';
import iconDiscipline from '../../assets/logo_discipline.svg';
import iconDetermination from '../../assets/logo_determination.svg';
import iconSuccess from '../../assets/logo_success.svg';
import '../../styles/style.css';

class MissionPanel extends Component {

    render() {
        return (
            <React.Fragment>
                <section className='section section-mission'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div id='container-mission' className='col-10'>
                                <div className='container'>
                                    <div className='row row-mission-title'>NOS VALEURS</div>
                                    <div className='row'>
                                        <Mission logo={iconDiscipline} textDescription='icon discipline' missionName='Rigueur'/>
                                        <Mission logo={iconDetermination} textDescription='icon determination' missionName='Perséverance'/>
                                        <Mission logo={iconSuccess} textDescription='icon success' missionName='Réussite'/>
                                    </div>
                                </div>
                            </div>
                            <div className='col-1'></div>
                        </div>
                    </div>
                </section>
                <section className='section section-responsive-mission'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div id='container-mission' className='col-10'>
                                <div className='container'>
                                    <div className='row row-mission-title'>NOS VALEURS</div>
                                    <div className='row'>
                                        <Mission logo={iconDiscipline} textDescription='icon discipline' missionName='Rigueur'/>
                                        <Mission logo={iconDetermination} textDescription='icon determination' missionName='Perséverance'/>
                                        <Mission logo={iconSuccess} textDescription='icon success' missionName='Réussite'/>
                                    </div>
                                </div>
                            </div>
                            <div className='col-1'></div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export { MissionPanel };