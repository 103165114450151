import { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Language, SocialMedia } from '../index';
import '../../styles/style.css';

class Header extends Component<{[name: string]: any}> {

    render() {
        return (
            <div id='header' className='container container-header'>
                <Row className='row-header'>
                    <Col xs={9} md={11}>
                        <SocialMedia/>
                    </Col>
                    <Col xs={3} md={1}>
                        <Language {...this.props}/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export { Header };