import React, { Component } from 'react';
import { Header, Menu, Footer } from '../../components';

class School extends Component<{}, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentLanguage: 'FR'
        };
    }

    toggleLanguage = () => {
        let currentLanguage = (this.state.currentLanguage === 'FR') ? 'EN' : 'FR';
        this.setState({currentLanguage});
    };

    getSchoolPanel = () => {
        return (
            <React.Fragment> {/* TODO Review CSS classes */}
                <section className='section section-banner-img' style={{'paddingBottom': '0px', 'paddingTop': '0px'}}>
                    <div className='row block-programmes-banner'></div>
                    <div className='col'>
                        <div className='row block-programmes-title'>Annonce</div>
                        <div className='col' style={{'position': 'relative', 'paddingTop': '75px', 'paddingBottom': '75px', 'width': '75%', 'margin': 'auto'}}>
                            <div className='row block-school-content'>
                                <p>La rentrée académique aura lieu le lundi 31 octobre 2022 à 7h30 au CFSS-MVF (Complexe de Formation des Sciences de Santé - Marc-Vivien FOÉ).</p>
                                <br/>
                                <p>Les fiches de modalités et les formulaires d'inscription sont déjà disponibles.</p>
                                <br/>
                                <p>Le Complexe de Formation des Sciences de Santé - Marc-Vivien FOÉ (CFSS-MVF) est situé à Yaoundé au quartier Biteng Maetur 
                                    au niveau du commissariat du 20ème arrondissement.
                                </p>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <p><span style={{'textDecoration': 'underline'}}>NB:</span> Les inscriptions ont déjà débuté.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='section section-responsive-banner-img' style={{'paddingTop': '50px', 'paddingBottom': '50px'}}>
                    <div className='row block-programmes-banner'></div>
                    <div className='col'>
                        <div className='row block-programmes-title'>Annonce</div>
                        <div className='col' style={{'position': 'relative', 'paddingTop': '10px', 'paddingBottom': '10px', 'width': '100%', 'margin': 'auto'}}>
                            <div className='row block-school-content'>
                                <p>La rentrée académique aura lieu le lundi 31 octobre 2022 à 7h30 au CFSS-MVF (Complexe de Formation des Sciences de Santé - Marc-Vivien FOÉ).</p>
                                <br/>
                                <p>Les fiches de modalités et les formulaires d'inscription sont déjà disponibles.</p>
                                <br/>
                                <p>Le Complexe de Formation des Sciences de Santé - Marc-Vivien FOÉ (CFSS-MVF) est situé à Yaoundé au quartier Biteng Maetur 
                                    au niveau du commissariat du 20ème arrondissement.
                                </p>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <p><span style={{'textDecoration': 'underline'}}>NB:</span> Les inscriptions ont déjà débuté.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div className='col'>
                <div className='row'><Header language={this.state.currentLanguage} toggleLanguage={this.toggleLanguage}/></div>
                <div className='row'><Menu language={this.state.currentLanguage} toggleLanguage={this.toggleLanguage}/></div>
                <div className='row'>{this.getSchoolPanel()}</div>
                <div className='row'><Footer/></div>
            </div>
        );
    }
}

export { School };