import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Logo, Language, VerticalBar, HorizontalBar } from '../index';
import logo from '../../assets/logo_1.png'; // TODO
import menuIcon from '../../assets/menu_icon.svg';
import menuCloseIcon from '../../assets/menu_close_icon.svg';
import '../../styles/style.css';

class Menu extends Component<{[name: string]: any}, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            menuOpen: false,
            menuItems: [
                {
                    id: 'school', 
                    text: `L'école`, 
                    link: '/school', 
                    subItems: [
                        {title: 'À propos', url: '/school'},
                        {title: 'Histoire', url: '/school'},
                        {title: 'Mot de la fondatrice', url: '/school'} // TODO
                    ],
                    displayed: false
                },
                {id: 'programmes', text: 'Programmes', link: '/#programmes', subItems: [], displayed: false},
                {id: 'admission', text: 'Admission', link: '/school', subItems: [], displayed: false}
            ]
        };
    }

    handleMenuChange = () => {
        this.setState({menuOpen: !this.state.menuOpen});
    }

    handleMenuDisplay = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, itemId: string) => {
        const { menuItems } = this.state;
        menuItems.forEach((item: { id: string; displayed: boolean, subItems: [] }) => {
            if(item.id === itemId && item.subItems.length > 0) {
                event.preventDefault();
                item.displayed = !item.displayed;
            }
        });
        this.setState({menuItems});
    }

    getMenuItems = (responsive: boolean = false) => {
        const { menuItems } = this.state;
        return (
            responsive ? 
            <nav className='menu-title-items'>
                <ul>
                    {
                        menuItems.map((item: any) => {
                            return (
                                <>
                                    <li>
                                        <a href={(item.id === 'programmes') ? `${item.link}-responsive` : `${item.link}`}>{item.text}</a> {/* TODO */}
                                    </li>
                                    <HorizontalBar style={{ width: '80%', height: '1px', 'marginTop': '15px', 'marginBottom': '15px', 'marginLeft': '40px', background: 'rgb(188, 184, 184)' }} />
                                </>
                            );
                        })
                    }
                </ul>
            </nav>
            :
            <div className='row'>
                {
                    menuItems.map((item: any) => {
                        return (<div className='col-4 menu-item'>
                                    <div className='row menu-item-container'>
                                        <a className='menu-item-link' onClick={event => this.handleMenuDisplay(event, item.id)} href={item.link}>{item.text}</a>
                                    </div>
                                    <div className='container menu-subitem-container'>
                                        {item.displayed ? this.getSubMenu(item.subItems) : ''}
                                    </div>
                                </div>);
                    })
                }
            </div>
        );
    }

    getSubMenu = (subItems: Array<{title: string, url: string}>) => {
        return (
            subItems.map((subItem) => {
                return <div className='row menu-subitem'>
                            <a className='menu-subitem-link' href={subItem.url}>{subItem.title}</a>
                        </div>
            })
        );
    }

    render() {
        return (
            <React.Fragment>
                <div id='menu' className='container'>
                    <div className='row'>
                        <div className='col-2'><Logo id='logo' logo={logo} linkClass='logo-link' imageClass='logo-img' textDescription='CFSS MVF logo'/></div>
                        <div className='col-7'>{this.getMenuItems()}</div>
                        <div className='col-1 menu-divider'>
                            <VerticalBar style={{background: 'rgb(188, 184, 184)', width: '1px', 'marginTop': '55px', 'marginLeft': '-20px'}} />
                        </div>
                        <div className='col-2 menu-contact'>
                            <div className='row menu-button-list'>
                                <div className='col-6'>
                                    <Button className='menu-contact-btn' aria-label='Contact'>
                                        <a href='tel:+237698556440'> {/* TODO */}
                                            <div className='container menu-contact-container'>
                                                <span aria-hidden='true'>
                                                    <i className='fa-solid fa-phone menu-contact-icon'/>
                                                </span>
                                                <span>Contact</span>
                                            </div>
                                        </a>
                                    </Button>
                                </div>
                                <div className='col-6'>
                                    <Button className='menu-contact-btn'>
                                        <a href='mailto:accueil@cfss-mvfoe.com'> {/* TODO */}
                                            <div className='container menu-contact-container'>
                                                <span aria-hidden='true'>
                                                    <i className='fa-solid fa-envelope menu-contact-icon'/>
                                                </span>
                                                <span>Contact</span>
                                            </div>
                                        </a>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='menu-responsive'>
                    <div className='row'>
                        <div className='col-5'>
                            <Logo id='logo' logo={logo} linkClass='logo-link' imageClass='logo-img' textDescription='CFSS MVF logo'/>
                        </div>
                        <div className='col-4'>
                            <Language {...this.props}/>
                        </div>
                        <div className='col-3'>
                            <img src={this.state.menuOpen ? menuCloseIcon : menuIcon} className='menu-icon' onClick={this.handleMenuChange} alt='menu icon'/>
                        </div>
                    </div>
                    <div className={this.state.menuOpen ?  'menu-responsive-open' : 'menu-responsive-close'}>
                        <div className='menu-title'>Menu</div>
                        <HorizontalBar style={{width: '80%', height: '1px', 'marginTop': '15px', 'marginBottom': '15px', 'marginLeft': '40px', background: 'rgb(188, 184, 184)'}}/>
                        {this.getMenuItems(true)}
                        <div className='row menu-responsive-button-list'>
                            <div className='col-6'>
                                <Button className='menu-contact-btn' aria-label='Contact'>
                                    <a href='tel:+237698556440'>{/* TODO */}
                                        <div className='container menu-contact-container'>
                                            <span aria-hidden='true'>
                                                <i className='fa-solid fa-phone menu-contact-icon'/>
                                            </span>
                                            <span>Contact</span>
                                        </div>
                                    </a>
                                </Button>
                            </div>
                            <div className='col-6'>
                                <Button className='menu-contact-btn'>
                                    <a href='mailto:accueil@cfss-mvfoe.com'>{/* TODO */}
                                        <div className='container menu-contact-container'>
                                            <span aria-hidden='true'>
                                                <i className='fa-solid fa-envelope menu-contact-icon'/>
                                            </span>
                                            <span>Contact</span>
                                        </div>
                                    </a>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export { Menu };