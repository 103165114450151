import { Component } from 'react';
import { Logo } from '../index';
import '../../styles/style.css';

class Mission extends Component<{[name: string]: any}> {

    render() {
        return (
            <div className='col-4'>
                <div className='row'>
                    <Logo logo={this.props.logo} className='row-mission-logo' imageClass='footer-logo-img' textDescription={this.props.textDescription}/>
                </div>
                <div className="row row-mission-name">{this.props.missionName}</div>
            </div>
        );
    }
}

export { Mission };