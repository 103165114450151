import React, { Component } from 'react';
import { Banner, Header, Menu, MissionPanel, Programmes, Footer } from '../../components';

class Home extends Component<{}, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentLanguage: 'FR'
        };
    }

    toggleLanguage = () => {
        let currentLanguage = (this.state.currentLanguage === 'FR') ? 'EN' : 'FR';
        this.setState({currentLanguage});
    };

    render() {
        return (
            <div className='col'>
                <div className='row'><Header language={this.state.currentLanguage} toggleLanguage={this.toggleLanguage}/></div>
                <div className='row'><Menu language={this.state.currentLanguage} toggleLanguage={this.toggleLanguage}></Menu></div>
                <div className='row'><Banner></Banner></div>
                <div className='row'><MissionPanel></MissionPanel></div>
                <div className='row'><Programmes></Programmes></div>
                <div className='row'><Footer></Footer></div>
            </div>
        );
    }
}

export { Home };