import { Component } from 'react';
import '../../styles/style.css';

class Logo extends Component<{[name: string]: any}> {

    render() {
        return (
            <div id={this.props.id} {...this.props}>
                <a className={this.props.linkClass} href='/'>
                    <img src={this.props.logo} className={this.props.imageClass} alt={this.props.textDescription}/>
                </a>
            </div>
        );
    }
}

export { Logo };