import React, { Component } from 'react';
import logo from '../../assets/logo_1.png'; // TODO
import stepper from '../../assets/arrow_up.svg';
import facebookLogo from '../../assets/facebook_bw.png';
import twitterLogo from '../../assets/twitter_bw.png';
import instagramLogo from '../../assets/instagram_bw.png';
import { Logo, HorizontalBar } from '../index';
import '../../styles/style.css';

class Footer extends Component<{}, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            menusFR: [
                {
                    id: 'school',
                    title: 'NOTRE ECOLE',
                    items: [
                        {value: 'À propos', type: 'link', url: '/school'},
                        {value: 'Histoire', type: 'link', url: '/school'},
                        {value: 'Mot de la fondatrice', type: 'link', url: '/school'}
                    ],
                    display: false
                },
                {
                    id: 'programmes',
                    title: 'NOS PROGRAMMES', 
                    items: [
                        {value: 'Aide-Soignante(s) Généraliste', type: 'link', url: '/#programmes'},
                        {value: 'Infirmier(e)', type: 'link', url: '/#programmes'},
                        {value: 'Infirmier(e) Spécialisé(e): Gérontologie', type: 'link', url: '/#programmes'},
                        {value: 'Sage-Femme / Maïeuticien', type: 'link', url: '/#programmes'},
                        {value: 'Technicien(ne) Médico-Sanitaire: Kinésithérapie', type: 'link', url: '/#programmes'},
                        {value: 'Technicien(ne) Médico-Sanitaire: Psychomotricité & Relaxation', type: 'link', url: '/#programmes'}
                    ],
                    display: false
                },
                {
                    id: 'contact',
                    title: 'CONTACT',
                    items: [
                        {value: 'Complexe Marc Vivien Foé, Biteng, Yaoundé', type: 'position'},
                        {value: 'Tél / WhatsApp : +237 6 98 55 64 40', type: 'phone'},
                        {value: 'accueil@cfss-mvfoe.com', type: 'email'}
                    ],
                    display: false
                }
            ]
        };
    }

    handleTitleClick = (event: React.MouseEvent<HTMLHeadingElement, MouseEvent>, id: string) => {
        const menu: {id: string, title: string, items: Array<{value: string, type: string}>, display: boolean}[] = this.state.menusFR;
        const index = menu.findIndex((elt) => elt.id === id);
        menu[index].display = !menu[index].display;
        this.setState({menuFR: menu});
    }

    getContentBlock = (size: number, menu: {id: string, title: string, items: Array<{value: string, type: string, url: string}>, display: boolean}, responsive: boolean = false) => {
        return (
            responsive ? 
                    <div className={`footer-col-content`}>
                        <h1 onClick={event => this.handleTitleClick(event, menu.id)}>{menu.title}</h1>
                        <HorizontalBar style={{width: '250px', height: '1px', 'marginTop': '15px', 'marginBottom': '15px', background: 'black'}}/>
                        <ul>
                            {menu.display ? this.getContactLinks(menu.items) : ''}
                        </ul>
                    </div>
                    :
                    <div className={`col-${size} footer-col-content`}>
                        <HorizontalBar style={{width: '50px', 'marginBottom': '20px', background: 'black'}}/>
                        <h1>{menu.title}</h1>
                        <ul>
                            {this.getContactLinks(menu.items)}
                        </ul>
                    </div>
        );
    }

    getContactLinks = (items: Array<{value: string, type: string, url: string}>) => {
        return items.map(elt => {
            switch(elt.type) {
                case 'email':
                    return <a href={`mailto:${elt.value}`}>{elt.value}</a>;
                case 'phone':
                    return <a href={`tel:${elt.value.split(':')[1]}`}>{elt.value}</a>;
                case 'position':
                    return <a href='https://goo.gl/maps/iuxF1kwBKwzubU6HA' target="_blank" rel="noreferrer">{elt.value}</a>;
                case 'link':
                    return <a href={elt.url}>{elt.value}</a>;
                case 'text':
                default:
                    return <li>{elt.value}</li>;
            }
        });
    }

    getSocialBlock = () => {
        return (
            <div className='row row-social'>
                <div className='col-4'>
                    <a href='https://www.facebook.com/cfss.mvfoe' target="_blank" rel="noreferrer" title="facebook icon">
                        <img src={facebookLogo} className='footer-social-img' alt='Facebook logo'/>
                    </a>
                </div>
                <div className='col-4'>
                    <a href='https://www.twitter.com' target="_blank" rel="noreferrer" title="twitter icon">
                        <img src={twitterLogo} className='footer-social-img' alt='Twitter logo'/>
                    </a>
                </div>
                <div className='col-4'>
                    <a href='https://www.instagram.com' target="_blank" rel="noreferrer" title="instagram icon">
                        <img src={instagramLogo} className='footer-social-img' alt='Instagram logo'/>
                    </a>
                </div>
            </div>
        );
    }

    getContent = () => {
        return (
            <div className='footer-content'>
                <div className='row row-content'>
                    {this.getContentBlock(3, this.state.menusFR[0])}
                    {this.getContentBlock(5, this.state.menusFR[1])}
                    {this.getContentBlock(4, this.state.menusFR[2])}
                </div>
                {this.getSocialBlock()}
                <div className='row row-copyright'>
                    © 2022 par CFSS MVF.
                </div>
            </div>
        );
    }

    getStepper = () => {
        return (
            <div className='footer-stepper'>
                <a id='footer-stepper-link' href='#header'>
                    <img src={stepper} className='footer-stepper-img' alt='Stepper'/>
                </a>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <section className='section section-footer'>
                    <div id='footer' className='row row-footer'>
                        <div className='col-2'><Logo className='footer-logo' logo={logo} imageClass='footer-logo-img' textDescription='CFSS MVF logo'/></div>
                        <div className='col-8'>{this.getContent()}</div>
                        <div className='col-2'>{this.getStepper()}</div>
                    </div>
                </section>
                <section className='section section-responsive-footer'>
                    <div id='footer' className='row row-footer'>
                        <div className='row row-footer-social'>{this.getSocialBlock()}</div>
                        <div className='row row-footer-content'>
                            {this.getContentBlock(0, this.state.menusFR[0], true)}
                            {this.getContentBlock(0, this.state.menusFR[1], true)}
                            {this.getContentBlock(0, this.state.menusFR[2], true)}
                        </div>
                        <div className='row row-copyright'>
                            © 2022 par CFSS MVF.
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export { Footer };