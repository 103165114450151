import React, { Component } from 'react';
import '../../styles/style.css';

class Programmes extends Component {

    programmes = [
        {id: 1, title: 'Aide-Soignant(e) Généraliste'},
        {id: 2, title: 'Infirmier(e)'},
        {id: 3, title: 'Infirmier(e) Spécialisé(e): Gérontologie'},
        {id: 4, title: 'Sage-Femme/Maïeuticien'},
        {id: 5, title: 'Technicien(ne) Medico-Sanitaire: Kinésithérapie'},
        {id: 6, title: 'Technicien(ne) Medico-Sanitaire: Psychomotricité & Relaxation'}
    ];

    getProgrammes = () => {
        return (
            <div>
                {   
                    this.programmes.map(elt => 
                        <div className='row'>
                            <a className='row-programmes' href='#programmes'>{elt.title}</a>
                        </div>)
                    }
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <section id='programmes' className='section section-programmes'>
                    <div className='container block-programmes'>
                        <div className='row row-programmes-title'>Trouver votre voie en fonction de votre profil</div>
                        {this.getProgrammes()}
                    </div>
                </section>
                <section id='programmes-responsive' className='section section-responsive-programmes'>
                    <div className='container block-programmes'>
                        <div className='row row-programmes-title'>Trouver votre voie en fonction de votre profil</div>
                        {this.getProgrammes()}
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export { Programmes };