import { Component, ReactNode } from 'react';
import '../../../styles/style.css';

class VerticalBar extends Component<{[name: string]: any}> {

    render(): ReactNode {
        return (
            <div className='vertical-bar' style={this.props.style}></div>
        )
    }
}

export { VerticalBar };