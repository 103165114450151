import React, { Component } from 'react';
import { Col } from 'reactstrap';
import '../../styles/style.css';

class Banner extends Component {

    welcomeMessage = 'Bienvenue au Complexe de Formation des Sciences de Santé Marc Vivien FOÉ';

    render() {
        return (
            <React.Fragment>
                <section className='section section-banner-img'>
                    <div className='row'>
                        <Col lg={{ size: 8, offset: 2 }} className='text-white text-center'>
                            <h4 className='home-title'>{this.welcomeMessage}</h4>
                        </Col>
                    </div>
                </section>
                <section className='section section-responsive-banner-img'>
                    <div className='row'>
                        <Col lg={{ size: 8, offset: 2 }} className='text-white text-center'>
                            <h4 className='home-title'>{this.welcomeMessage}</h4>
                        </Col>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export { Banner };
