import React, { Component } from 'react';
import { Header, Menu, Footer } from '../../components';

class ProgrammesPage extends Component<{[name: string]: any}, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentLanguage: 'FR'
        };
    }

    toggleLanguage = () => {
        let currentLanguage = (this.state.currentLanguage === 'FR') ? 'EN' : 'FR';
        this.setState({currentLanguage});
    };

    getProgrammesPanel = () => {
        const {programmes} = this.props;
        return (
            <React.Fragment>
                <section className='section section-banner-img' style={{'paddingBottom': '0px', 'paddingTop': '0px'}}>
                    <div className='row block-programmes-banner'></div>
                    <div className='col'>
                        <div className='row block-programmes-title'>Formations</div>
                        <div className='col' style={{'position': 'relative', 'paddingTop': '75px', 'paddingBottom': '75px', 'width': '75%', 'margin': 'auto'}}>
                            {
                                programmes.map((item: {title: string}) => {
                                    return (
                                        <div style={{'width': '70%', 'margin': 'auto', marginBottom: '5%', 'background': 'black', 'opacity': '0.7', borderRadius: '15px'}}>
                                            <div className='row block-programmes-item'>{item.title}</div>
                                            <div className='row block-programmes-item'>
                                                <div className='col-6'>
                                                    <a className='row' href='/#' target="_blank" rel="noreferrer" title="registration form link">Formulaire d'inscription</a>
                                                </div>
                                                <div className='col-6'>
                                                    <a className='row' href='/#' target="_blank" rel="noreferrer" title="terms and conditions link">Modalités</a>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div className='col'>
                <div className='row'><Header language={this.state.currentLanguage} toggleLanguage={this.toggleLanguage}/></div>
                <div className='row'><Menu language={this.state.currentLanguage} toggleLanguage={this.toggleLanguage}/></div>
                <div className='row'>{this.getProgrammesPanel()}</div>
                <div className='row'><Footer/></div>
            </div>
        );
    }
}

export { ProgrammesPage };