import { Component } from 'react';
import { Col } from 'reactstrap';
import facebookLogo from '../../assets/facebook.png';
import twitterLogo from '../../assets/twitter.png';
import instagramLogo from '../../assets/instagram.png';
import '../../styles/style.css';

/**
 * TODO
 * - FB & Linkedin pages
 * - Review layout between icons
 */
class SocialMedia extends Component {

    render() {
        return (
            <div id='social-media' className='container'>
                <div className='row'>
                    <Col xs={9} md={10}></Col>
                    <Col xs={3} md={2}>
                        <div className='row social-media-row'>
                            <div className='col-4'>
                                <a className='social-media-item' href='https://www.facebook.com/cfss.mvfoe' target="_blank" rel="noreferrer">
                                    <img src={facebookLogo} className='social-media-logo' alt='Facebook logo'/>
                                </a>
                            </div>
                            <div className='col-4'>
                                <a className='social-media-item' href='https://www.twitter.com' target="_blank" rel="noreferrer">
                                    <img src={twitterLogo} className='social-media-logo' alt='Twitter logo'/>
                                </a>
                            </div>
                            <div className='col-4'>
                                <a className='social-media-item' href='https://www.instagram.com' target="_blank" rel="noreferrer">
                                    <img src={instagramLogo} className='social-media-logo' alt='Instagram logo'/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </div>
            </div>
        );
    }
}

export { SocialMedia };