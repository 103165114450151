import { Component } from 'react';
import './LandingPage.css';

class LandingPage extends Component {

    render() {
        return (
            <div className='landing-page'></div>
        );
    }
}

export { LandingPage };